<template>
    <div>
        <div class="topimage">
            <el-image style="width:100%" :src="require('@/assets/laboratory/banner3.jpg')"></el-image>
        </div>
        <div class="appmain">
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">高温试验，低温试验，交变湿热试验等</div>
                </div>
                <div class="yongtu">用途：模拟恶劣的气候环境，检测产品在各种环境温度下的性能指标；评估产品的性能指标是否符合设计要求</div>
                <div class="canshu">主要技术参数：容积：400L，温度范围：-40℃ ~ 150℃，湿度范围：25% ~ 98%，温变率：1℃/min，温度波动度：±0.5，湿度容差：±3%R.H</div>
                <div class="shebei">设备：高低温湿热试验箱</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">电热恒温箱</div>
                </div>
                <div class="yongtu">用途：检测产品长期工作在高温环境下的可靠性，评估产品的寿命是否符合设计要求。</div>
                <div class="canshu">主要技术参数：容积：1200mmX800mmX600mm，温度范围：20℃ ~ 90℃，温度波动度：±1℃</div>
                <div class="shebei">设备：电热恒温箱</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">氙灯老化试验</div>
                </div>
                <div class="yongtu">用途：模拟地面阳光辐射对产品的影响，检测产品在不同光波照射环境下的性能（如面膜不能开胶、脱落，外壳不能变形，液晶显示清晰度不应模糊等），评估产品的性能是否符合设计要求。</div>
                <div class="canshu">主要技术参数：容积：400mmX500mmX450mm，温度范围：40℃ ~ 80℃，氙灯功率：1.5KW±10%</div>
                <div class="shebei">设备：氙灯老化试验箱</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">盐雾试验</div>
                </div>
                <div class="yongtu">用途：检测产品耐受盐雾腐蚀的能力（其耐受程度随选用的严酷等级而定），从而评估产品的耐腐蚀性是否符合设计要求。</div>
                <div class="canshu">主要技术参数：容积：500mmX630mmX450mm，温度范围：室内温度 ~ 55℃，盐雾沉降量：(1-2) ml/80cm2 /h，喷雾方式：气动式，连续、周期喷雾</div>
                <div class="shebei">设备：盐雾腐蚀试验箱</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">淋雨试验(防护试验)</div>
                </div>
                <div class="yongtu">用途：检测产品前面板的防水性能，评估产品的 IP 防护等级是否符合设计要求。</div>
                <div class="canshu">主要技术参数：容积：1000mmX1000mmX2700mm，喷水流量：IP5(等级)12.5±1（L/min）,IP6(等级)100±5（L/min），喷水压力：100 ~ 150kpa，转盘直径：500mm</div>
                <div class="shebei">设备：淋雨试验箱</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">沙尘试验(防护试验)</div>
                </div>
                <div class="yongtu">用途：检测产品前面板的防尘性能和工作沙尘环境下控制器的性能，评估产品的 IP防护等级是否符合设计要求。</div>
                <div class="canshu">主要技术参数：容积：1000mmX1000mmX1000mm，网标称线径：50um，线间标称间距：75 um，真空泵：60 ~ 600L/H，气流速度：不大于 2m/s</div>
                <div class="shebei">设备：沙尘试验箱</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">落球冲击试验</div>
                </div>
                <div class="yongtu">用途：检测产品的液晶显示窗所用材料的性能，评估产品的液晶显示窗所用材料的硬度是否符合设计要求。</div>
                <div class="canshu">主要技术参数：冲击高度范围：0 ~ 2000mm 可调，钢球规格：33g,50g,64g,95g,110g,134g，控制方式：电磁式控制落下，单次冲击</div>
                <div class="shebei">设备：落球冲击试验机</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">跌落试验</div>
                </div>
                <div class="yongtu">用途：检测并评估产品在生产、使用、存储、运输过程中可能遇到的意外跌落、冲击压力及震动时现时包装方法及材料能否提供有效保护、及验证产品各结构或部件的耐冲击强度。</div>
                <div class="canshu">主要技术参数：跌落高度：300mm ~ 1500mm，最大载荷：80Kg，测试空间：800mmX600mmX800mm</div>
                <div class="shebei">设备：跌落试验机</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">振动试验</div>
                </div>
                <div class="yongtu">用途：模拟产品安装到发电机组上使用过程中产生的振动，检测产品在振动环境的性能（如吸合的继电器不应释放；内部的元器件不应掉落；端子头不应脱落等），来评估产品的可靠性是否符合设计要求。</div>
                <div class="canshu">主要技术参数：推力：5880N(600Kgf)，频率范围：5Hz ~ 5000Hz，位移：51mm，加速度：980m/s²(100g)，额定载荷：200Kg，振动方式：随机、正弦、冲击</div>
                <div class="shebei">设备：电动振动试验机</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">冲击试验</div>
                </div>
                <div class="yongtu">用途：检测产品在冲击、碰撞环境的性能（如吸合的继电器不应释放；内部的元器件不应掉落；端子头不应脱落等），来评估产品的可靠性是否符合设计要求。</div>
                <div class="canshu">主要技术参数：最大负载：100KG，面尺寸:500*700mm，连续工作次数：碰撞试验 3000 次，冲击试验 18 次，台面加速度不均匀度：<20%,最大跌落高度：60mm，碰撞脉冲重复频率:0 ~ 80 次/分</div>
                <div class="shebei">设备：冲击碰撞测试台</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">水平垂直燃烧试验</div>
                </div>
                <div class="yongtu">用途：验产品的防火性能及防火等级，评估产品的安全性是否符合设计要求。</div>
                <div class="canshu">主要技术参数：试验应按IEC 60695-2-11规定，以下列温度进行:端子座:960℃士100℃ ;端子盖和表壳:650℃士100℃;作用时间:30s 士1s</div>
                <div class="shebei">设备：水平垂直燃烧试验仪</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">灼热丝试验</div>
                </div>
                <div class="yongtu">用途：用于模拟因灼热元件或过载电阻之类的热源在短时间内造成热应力的着火危险性，利用模拟技术评点着火危险。</div>
                <div class="canshu">主要技术参数：温度范围：0--1000℃，施燃时间：0s～999.9s 可调，一般设定为30s；持燃时间：0s～999.9s，自动记录，手动暂停；试验空间：≥0.5m3；外形尺寸：L1120 * W520 * H1250mm；温度传感器：K 型绝缘式铠装电偶（Φ0.5mm）</div>
                <div class="shebei">设备：灼热丝试验仪</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">高温试验，低温试验，交变湿热试验等</div>
                </div>
                <div class="yongtu">用途：检测大型产品在高低温、湿热环境下的可靠性，评估产品的寿命是否符合设计要求。</div>
                <div class="canshu">主要技术参数：容积：4.7m*2.5m*2.8m ,温度范围：-30℃ ~ 80℃，度范围：25% ~ 98%</div>
                <div class="shebei">设备：步入式高低温湿热试验箱</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#ffffff')
    },
    // 在销毁前，将body的style属性删除
    deactivated() {
        document.querySelector('body').removeAttribute('style')
    },
}
</script>
<style lang="scss" scoped>
    .appmain{
        width: 50%;
        margin: 0 auto;
        margin-top: 20px;
        .item{
            margin-bottom: 30px;
            .titles{
                display: flex;
                position: relative;
                .ticon{
                    width: 10px;
                    height: 10px;
                    background: #0052D9;
                    border-radius: 50%;
                    z-index: 2;
                    margin-top: 10px;
                }
                .ticon2{
                    width: 10px;
                    height: 10px;
                    background: #2DC84D;
                    border-radius: 50%;
                    position: absolute;
                    left: 6px;
                    margin-top: 10px;
                }
                .wenzi{
                    font-size: 20px;
                    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                    font-weight: bold;
                    color: #333333;
                    line-height: 30px;
                    margin-left: 20px;
                }
            }
            .yongtu{
                font-size: 14px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #333333;
                margin-top: 15px;
            }
            .yongtu{
                font-size: 14px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #333333;
                margin-top: 15px;
                line-height: 30px;
            }
            .canshu{
                font-size: 14px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #333333;
                margin-top: 15px;
                line-height: 30px;
            }
            .shebei{
                font-size: 14px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #333333;
                margin-top: 15px;
            }
        }
        
    }
</style>